.Result {
    background: #bfdbf5;
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;

    @keyframes slideBackground {
        0% {
            transform: scaleX(1);
        }
        100% {
            transform: scaleX(0);
        }
    }
    
    &::after {
        content: '';
        width: 70%;
        max-width: calc(100% - 700px);
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        background: #f0eee7;
        transform-origin: 100% 50%;
        animation: slideBackground 1s forwards;
    }

    @media screen and (max-width: 1280px) {
        &::after {
            width: calc(100% - 500px)!important;
            max-width: unset!important;
        }
    }

    @media screen and (max-width: 1000px) {
        &::after {
            width: 0!important;
        }
    }

    &-filler {
        flex: 1;
    }

    &-left {
        width: 100%;
        max-width: 1000px;
        padding: 100px;
        position: relative;

        @media screen and (max-height: 1000px) {
            padding-top: 0px;
            padding-bottom: 50px;
        }

        @media screen and (max-width: 1399px) {
            max-width: unset;
        }

        @media screen and (max-width: 500px) {
            padding: 50px;
            font-size: .75em;
        }

        @keyframes slideUp {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
        }

        &-title {
            font-size: 5em;
            font-family: Zington-Black;
            animation: slideUp 1s forwards;
            opacity: 0;
            position: relative;

            @media screen and (max-width: 1280px) {
                font-size: 3em;
            }
        }

        &-description {
            margin-top: 50px;
            font-size: 1em;
            line-height: 1.2em;
            font-family: Zington-Book;
            animation: slideUp 1s forwards;
            animation-delay: .2s;
            opacity: 0;
            white-space: pre-wrap;
        }

        &-linksTitle {
            width: 100%;
            margin-top: 50px;
            font-size: 2em;
            font-family: Zington-Black;
            animation: slideUp 1s forwards;
            animation-delay: .4s;
            opacity: 0;
        }

        &-linksText {
            margin-top: 1em;
            font-size: 1em;
            line-height: 1.2em;
            font-family: Zington-Book;
            animation: slideUp 1s forwards;
            animation-delay: .6s;
            opacity: 0;
            white-space: pre-wrap;
        }

        &-links {
            width: 100%;
            margin-top: 25px;
            animation: slideUp 1s forwards;
            animation-delay: .8s;
            opacity: 0;
            font-size: 1.2em;

            a {
                color: #bc0046;
                text-decoration: none;
                margin-right: 2em;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &-right {
        flex: 1;
        background: #bfdbf5;
        padding: 100px 100px 100px 100px;
        border-left: 1px solid rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
        max-width: 1000px;

        @media screen and (max-height: 1000px) {
            padding-top: 0px;
            padding-bottom: 50px;
        }

        @media screen and (max-width: 1399px) {
            padding: 0 100px 100px 100px;
        }

        @media screen and (max-width: 500px) {
            padding: 50px;
            font-size: .75em;
        }

        &-image {
            width: 200px;
            height: 200px;
            margin-bottom: 2em;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-title {
            font-size: 2em;
            font-family: Zington-Black;

            @media screen and (max-width: 1280px) {
                font-size: 1.5em;
            }
        }

        &-description {
            font-size: 1em;
            font-family: Zington-Book;
            margin-top: 1em;
        }

        &-contact {
            width: 100%;
            margin-top: 50px;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 1000px) {
                min-width: unset;
            }

            &-input {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &-row {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    flex-wrap: wrap;

                    &-field {
                        margin-right: 2em;
                        flex: 1;
                        min-width: 200px;
                        margin-bottom: 50px;

                        &-title {
                            color: #bc0046;
                            font-size: .75em;
                            letter-spacing: 1px;
                            font-family: Zington-Book;
                            text-transform: uppercase;
                        }

                        ::-webkit-input-placeholder { /* Edge */
                            color: #211F57;
                            opacity: .5;
                        }
                        
                        &-form {
                            border: none;
                            border-radius: unset;
                            margin: unset;
                            outline: none;
                            width: 100%;
                            color: #211F57;
                            padding: 1em 0 1em 0;
                            background: transparent;
                            border-bottom: 1px solid rgba(0,0,0,.1);
                            font-size: 1em;
                            font-family: Zington-Book;
                        }

                        &-check {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-top: 1em;

                            &-label {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                height: 36px;
                                cursor: pointer;

                                &-input {
                                    height: 2em;
                                    display: none;
                                    visibility: hidden;
                                    opacity: 0;

                                    &:checked ~ .Result-right-contact-input-row-field-check-label-button {
                                        border: 2px solid #211f5700;
                                        transition: .3s;

                                        &::before {
                                            transform: translate(-50%, -50%) scale(1);
                                            transition: .3s;
                                            border: 1px solid #211F57;
                                        }

                                        &::after {
                                            transform: translate(-50%, -50%) scale(2.5);
                                            opacity: 0;
                                            transition: .75s;
                                        }
                                        
                                        .Result-right-contact-input-row-field-check-label-button-checkmark {
                                            opacity: 1;
                                            transition: .3s;
                                            transition-delay: .3s;
                                        }
                                    }
                                }

                                &-button {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    margin-right: 1em;
                                    border: 1px solid #211F57;
                                    position: relative;

                                    &::before {
                                        content: '';
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) scale(0);
                                        width: 100%;
                                        height: 100%;
                                        background: #211F57;
                                    }

                                    &::after {
                                        content: '';
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) scale(0);
                                        width: 100%;
                                        height: 100%;
                                        background: #211F57;
                                        opacity: 1;
                                    }

                                    &-checkmark {
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translate(-56%, -70%) rotate(220deg) scale(.6);
                                        width: 20px;
                                        height: 20px;
                                        opacity: 0;

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            left: 0px;
                                            top: 0px;
                                            width: 3px;
                                            height: 15px;
                                            background: #f0eee7;
                                        }

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            left: 0px;
                                            top: 0px;
                                            width: 10px;
                                            height: 3px;
                                            background: #f0eee7;
                                        }
                                    }
                                }
                            }


                            &-text {
                                font-size: 1em;
                                a {
                                    color: #bc0046;
                                    text-decoration: none;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    button {
                        color: #211F57;
                        background: transparent;
                        border: 2px solid #211F57;
                        padding: 1em 4em 1em 2em;
                        margin: 50px 1.6em 0 0;
                        border-radius: 2em;
                        font-size: 1.25em;
                        font-family: Zington-Book;
                        outline: none;
                        cursor: pointer;
                        position: relative;

                        &.disabled {
                            pointer-events: none;
                            opacity: .25;
                        }

                        @keyframes rotateAnimation {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }

                        .Result-right-contact-input-row-inner, .Result-right-contact-input-row-inner2 {
                            opacity: 0;
                            transition: .1s;
                            transition-delay: .15s;
                        }

                        &.posting {
                            padding: 1em;
                            transition: .3s;
                            position: relative;
                            pointer-events: none;
                            
                            .Result-right-contact-input-row-text {
                                display: none;
                            }

                            .Result-right-contact-input-row-inner, .Result-right-contact-input-row-inner2 {
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                width: 100%;
                                height: 100%;
                                animation: rotateAnimation 1s infinite;
                                animation-delay: .15s;
                                animation-timing-function: linear;
                                opacity: 1;
    
                                &::after, &::before {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    background: #bfdbf5;
                                    position: absolute;
                                    left: 0px;
                                    top: 0px;
                                }

                                &::before {
                                    bottom: 0px;
                                    top: unset;
                                }
                            }

                            .Result-right-contact-input-row-inner2 {
                                animation: rotateAnimation 1.5s infinite;
                                animation-timing-function: linear;
                                animation-delay: .3s;
                            }
                            

                            &::before {
                                content: '115';
                                opacity: 0;
                            }

                            &::after {
                                content: unset;
                            }

                            &:hover {
                                color: #211F57;
                                background: transparent;
                            }
                        }

                        &.finished {
                            padding: 1em 2em 1em 2em;
                            transition: .3s;
                            background: #211F57;
                            color: #bfdbf5;
                            pointer-events: none;
                            
                            .Result-right-contact-input-row-text {
                                display: none;
                            }

                            @keyframes finishedPostingAnimation {
                                0% {
                                    transform: scaleX(1) scaleY(1);
                                    opacity: 1;
                                }
                                100% {
                                    transform: scaleX(1.25) scaleY(1.5);
                                    opacity: 0;
                                }
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                width: 100%;
                                height: 100%;
                                background: red;
                                background: #211F57;
                                border-radius: 2em;
                                animation: finishedPostingAnimation 1s forwards;
                            }

                            &::before {
                                content: 'Skickat';
                            }

                            &:hover {
                                background: #211F57;
                                color: #bfdbf5;
                            }
                        }
        
                        &::after {
                            content: '';
                            width: 5em;
                            height: 100%;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background: url(../../images/darkblue-arrow-right.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                        }
        
                        &:hover {
                            color: #F0EEE7;
                            background: #211F57;
                            border: 2px solid #211F57;
                            
                            &.b {
                                &::before {
                                    background: url(../../images/white-arrow-right.svg);
                                    background-position: center;
                                    background-repeat: no-repeat;
                                }
                            }
        
                            &::after {
                                background: url(../../images/white-arrow-right.svg);
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }

    &-overlay {
        position: absolute;
        left: 0;
        top: -190px;
        width: 100%;
        height: calc(100% + 190px);
        background: #bfdbf5de;
        opacity: 0;
        pointer-events: none;
        transition: .3s;
        transition-delay: .5s;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: 1399px) {
            max-width: unset;
        }

        @media screen and (max-width: 500px) {
            top: -100px;
            height: calc(100% + 100px);
            font-size: .75em;
        }

        &.active {
            opacity: 1;
            pointer-events: all;

            .Result-overlay-card {
                animation: slideUp .5s forwards;
                animation-delay: .5s;
                // animation-timing-function: linear;
            }
        }

        @keyframes slideUp {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
        }

        &-card {
            width: 90%;
            max-width: 800px;
            margin-top: 200px;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 15px 20px rgba(0,0,0,.05);
            padding: 100px;
            opacity: 0;

            @media screen and (max-width: 500px) {
                padding: 50px;
            }

            &-title {
                width: 100%;
                font-size: 2em;
                font-family: Zington-Black;
                animation: slideUp 1s forwards;
                animation-delay: .4s;
                opacity: 0;
            }
    
            &-text {
                margin-top: 1em;
                font-size: 1em;
                line-height: 1.2em;
                font-family: Zington-Book;
                animation: slideUp 1s forwards;
                animation-delay: .6s;
                opacity: 0;
                white-space: pre-wrap;
            }

            a {
                color: #bc0046;
                text-decoration: none;
                margin-top: 1em;
                display: block;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
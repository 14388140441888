@keyframes slideBackground {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);

    }
}

.Header {
    width: 100%;
    height: auto;
    position: relative;
    background: #bfdbf5;

    @media screen and (max-width: 1280px) {
        &::after {
            width: calc(100% - 500px)!important;
            max-width: unset!important;
        }
    }

    @media screen and (max-width: 1000px) {
        &::after {
            width: 0!important;
        }
    }

    &.result {
        &::after {
            animation: slideBackground 1s forwards;
        }
    }
    
    &::after {
        content: '';
        width: 70%;
        max-width: calc(100% - 700px);
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        background: #f0eee7;
        transform-origin: 100% 50%;
    }

    .logo {
        width: auto;
        height: 100px;
        margin: 50px 50px 40px 60px;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url(../../images/Zington_Logotyp_Pos_RGB.svg);

        @media screen and (max-width: 500px) {
            height: 50px;
            margin: 25px 25px 25px 30px;
        }
    }
}
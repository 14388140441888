.Main {
    width: 100%;
    height: auto;
    flex: auto;
    background: #BFDBF5;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 1280px) {
        &::after {
            width: calc(100% - 500px)!important;
            max-width: unset!important;
        }
    }

    @media screen and (max-width: 1000px) {
        &::after {
            width: 0!important;
        }
    }

    &::after {
        content: '';
        width: 70%;
        max-width: calc(100% - 700px);
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        background: #f0eee7;
        transform-origin: 100% 50%;
        z-index: 0;
    }

    &-pagination {
        width: 100%;
        height: auto;
        padding-left: 100px;
        display: flex;
        flex-direction: row;
        position: relative;
        background-color: transparent;

        @media screen and (max-width: 500px) {
            padding-left: 50px;
        }

        &-page {
            width: 10px;
            height: 10px;
            // background-color: rgba(255,255,255,.1);
            // background-color:#211F57;
            background-color:transparent;
            border: 1px solid #211F57;
            border-radius: 50%;
            margin-right: 10px;

            &.open {
                // background-color: rgba(0,0,0,.2);
                // background-color: #bc0046;
                background-color:#211F57;
            }
        }
    }

    .Sections {
        min-height: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow: hidden;
        position: relative;
        z-index: 1;

        & > div {
            flex: 1;
            display: flex;
            flex-direction: row;
        }
    }

    .Results {
        background: transparent;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
        // overflow: hidden;
    }
}
.Section {
    width: 100vw;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    @keyframes slideUp {
        0% {
          transform: translateY(20%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: .2;
        }
    }

    &-left {
        width: 30%;
        min-width: 700px;
        height: 100%;
        height: auto;
        background: #BFDBF5;
        padding: 200px 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-height: 1000px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media screen and (max-width: 1280px) {
            min-width: 500px;
            padding: 100px;
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        @media screen and (max-width: 500px) {
            padding: 50px;
            font-size: .75em;
            min-width: unset;
        }

        &-title {
            font-size: 4em;
            font-family: Zington-Black;
            animation: slideUp 1s forwards;
            opacity: 0;
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;

            @media screen and (max-width: 1280px) {
                font-size: 3em;
                
                &::after {
                    top: -50px!important;
                }
            }

            @media screen and (max-width: 500px) {
                &::after {
                    top: -40px!important;
                    left: -25px!important;
                }
            }

            &::after {
                content: attr(data-id);
                position: absolute;
                left: -50px;
                top: -60px;
                z-index: -1;
                font-family: Zington-Book;
                font-size: 2.5em;
                opacity: 0;
                animation: fadeIn 1s forwards;
                animation-delay: .5s;
            }
        }

        &-description {
            margin-top: 50px;
            font-size: 1.3em;
            line-height: 1.2em;
            font-family: Zington-Book;
            animation: slideUp 1s forwards;
            animation-delay: .2s;
            opacity: 0;
            color: #bc0046;
            white-space: pre-wrap;

            @media screen and (max-width: 1000px) {
                margin-bottom: 50px;
            }
        }

        &-filler {
            flex: 1;
        }

        &-buttons {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            &-forward {
                @media screen and (max-width: 1280px) {
                    padding: 1em 3em 1em 2em!important;
                }

                &.disabled {
                    pointer-events: none;
                    opacity: .25;
                }

                &-content {
                    background: unset;

                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }
            }

            &-back {
                @media screen and (max-width: 1280px) {
                    padding: 1em 2em 1em 3em!important;
                }

                &-content {
                    background: unset;

                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }
            }

            button {
                color: #211F57;
                background: transparent;
                border: 2px solid #211F57;
                padding: 1em 4em 1em 2em;
                margin: 0;
                border-radius: 2em;
                font-size: 1.25em;
                font-family: Zington-Book;
                outline: none;
                cursor: pointer;
                position: relative;

                &::after {
                    content: '';
                    width: 5em;
                    height: 100%;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    background: url(../../images/white-arrow-right.svg);
                    background: url(../../images/darkblue-arrow-right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.b {
                    padding: 1em 2em 1em 4em;

                    &::before {
                        content: '';
                        width: 5em;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        background: url(../../images/white-arrow-right.svg);
                        background: url(../../images/darkblue-arrow-right.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        transform: rotate(180deg);
                    }

                    &::after {
                        content: unset;
                    }
                }

                &:hover {
                    // transform: scale(1.05);
                    color: #211F57;
                    color: #F0EEE7;
                    background: transparent;
                    border: 2px solid #211F57;
                    background: #bc0046;
                    border: 2px solid #bc0046;

                    background: #211F57;
                    // color: #BFDBF5;
                    border: 2px solid #211F57;
                    
                    &.b {
                        &::before {
                            background: url(../../images/darkblue-arrow-right.svg);
                            background: url(../../images/white-arrow-right.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }

                    &::after {
                        background: url(../../images/darkblue-arrow-right.svg);
                        background: url(../../images/white-arrow-right.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    &-right {
        width: 70%;
        max-height: calc(100vh - 200px);
        background: #F0EEE7;
        padding: 0 100px 200px 100px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        overflow-x: hidden;

        @media screen and (max-width: 1350px) {
            padding: 0 50px 200px 50px;
        }

        @media screen and (max-width: 1280px) {
            padding: 20px 20px 100px 20px;
        }

        @media screen and (max-height: 1000px) {
            padding-bottom: 100px;
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
            max-height: unset;
            flex: 1;
            padding: 20px 20px 50px 20px;
        }

        @keyframes slideUp {
            0% {
              transform: translateY(20%);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
        }

        &-background {
            width: 100%;
            min-height: 200px;
            height: 100%;
            // opacity: 0;

            @keyframes fadeInImage {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: .5;
                }
            }

            &-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                filter: grayscale(100%);
                // transform: scale(1.2);
                // opacity: 1;
                animation: fadeInImage 1s forwards;
            }
        }

        &-question {
            width: 100%;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            animation: slideUp 1s forwards;
            animation-delay: calc(var(--id) * .1s + .25s);
            transform: translateY(20%);
            opacity: 0;
            margin-bottom: 25px;
            background: white;
            border-radius: 5px;
            overflow: hidden;
            flex-shrink: 0;
            box-shadow: 0 15px 20px rgba(0,0,0,.05);

            @media screen and (max-width: 1280px) {
                max-width: unset;
            }

            &:nth-last-child(2) {
                margin-bottom: 0;
            }

            &-scope {
                opacity: .5;
                text-transform: uppercase;
                font-size: .75em;
                padding: 30px 30px 0 30px;
                position: relative;
                // width: 50px;
                // height: 50px;
                // background: red;
                // top: 10px;
                // left: 30px;
                // position: absolute;

                // &::before {
                //     content: ' ';  
                //     width: 30px;
                //     height: 30px;  
                //     position: absolute;
                //     left: 0px;         
                //     background-image: url(../../images/Claremont_Ikoner_Rod_RGB_Mission.png);
                //     background-image: url(../../images/Claremont_Ikoner_Rod_RGB_Utveckling.png);

                //     background-size: contain;
                // }
            }


            &-title {
                width: 100%;
                max-width: 450px;
                padding: 5px 30px 30px 30px;
                color: #211F57;
                line-height: 1.4em;
            }

            &-input {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media screen and (max-width: 500px) {
                    flex-direction: column;
                }

                &-answer {
                    flex: 1;
                    border-top: 1px solid rgba(0,0,0,.1);
                    cursor: pointer;

                    &:last-child {
                        .Section-right-question-input-answer-inner {
                            border-right: unset;
                        }
                    }

                    @keyframes pop {
                        0% {
                          transform: scale(1);
                        }
                        20% {
                            transform: scale(1.05);
                        }
                        100% {
                            transform: scale(1);
                        }
                    }

                    &-button {
                        width: 0px;
                        height: 0px;
                        opacity: 0;
                        visibility: hidden;
                        display: none;
                        position: absolute;

                        &:checked ~ .Section-right-question-input-answer-inner {
                            background: #211F57;
                            color: #BFDBF5;
                            animation: pop .25s;
                        }
                    }

                    &-inner {
                        width: 100%;
                        height: 100%;
                        background: white;
                        color: #211F57;
                        font-family: Zington-Book;
                        border-right: 1px solid rgba(0,0,0,.1);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        transition: .3s;
                        cursor: pointer;
                        padding: 10px;

                        @media screen and (max-width: 500px) {
                            justify-content: flex-start;
                            padding: 10px 30px;
                        }

                        &:hover {
                            background: #BFDBF5;
                            color: #211F57;
                        }

                        &-number {
                            font-size: 1em;
                            // padding: 10px 0;
                        }

                        &-text {
                            margin-left: 1em;
                            font-size: .75em;
                            position: relative;
                            top: 2px;
                            opacity: .7;
                        }
                    }
                }
            }
        }

        &-buttons {
            margin-top: 50px;
            display: none;
            flex-direction: row-reverse;
            justify-content: space-between;

            @media screen and (max-width: 500px) {
                font-size: .75em;
            }

            @media screen and (max-width: 1000px) {
                display: flex;
            }

            &.disabled {
                display: none;
            }

            &-forward {
                @media screen and (max-width: 1280px) {
                    padding: 1em 3em 1em 2em!important;
                }

                &.disabled {
                    pointer-events: none;
                    opacity: .25;
                }

                &-content {
                    background: unset;

                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }
            }

            &-back {
                @media screen and (max-width: 1280px) {
                    padding: 1em 2em 1em 3em!important;
                }

                &-content {
                    background: unset;

                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }
            }

            button {
                color: #211F57;
                background: transparent;
                border: 2px solid #211F57;
                padding: 1em 4em 1em 2em;
                margin: 0;
                border-radius: 2em;
                font-size: 1.25em;
                font-family: Zington-Book;
                outline: none;
                cursor: pointer;
                position: relative;

                &::after {
                    content: '';
                    width: 5em;
                    height: 100%;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    background: url(../../images/darkblue-arrow-right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.b {
                    padding: 1em 2em 1em 4em;

                    &::before {
                        content: '';
                        width: 5em;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        background: url(../../images/darkblue-arrow-right.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        transform: rotate(180deg);
                    }

                    &::after {
                        content: unset;
                    }
                }

                &:hover {
                    color: #F0EEE7;
                    background: #211F57;
                    border: 2px solid #211F57;
                    
                    &.b {
                        &::before {
                            background: url(../../images/white-arrow-right.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }

                    &::after {
                        background: url(../../images/white-arrow-right.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}